type TranslationKey = "data_entry_title" | "submit_data" | "click_to_scan" | "entry_list_title" | "entry_list_header" | "settings" | "new_column" | "add";
const translations: Record<TranslationKey, any> = {
    "data_entry_title": {
        "en": "Data entry",
        "ro": "Introducere date"
    },
    "submit_data": {
        "en": "Submit",
        "ro": "Trimite"
    },
    "click_to_scan": {
        "en": "Click to scan",
        "ro": "Click scanare"
    },
    "entry_list_title": {
        "en": "View List",
        "ro": "Intrari"
    },
    "entry_list_header": {
        "en": "Entry list",
        "ro": "Lista de intrari"
    },
    "settings": {
        "en": "Settings",
        "ro": "Setari"
    },
    "new_column":{
        "en": "New column",
        "ro": "Coloana noua"
    },
    "add": {
        "en": "Add",
        "ro": "Adauga",
    }
}

export default function getTranslation(key: TranslationKey){
    const language = process.env.REACT_APP_MONTHS_LANGUAGE || "ro"
    try{
        return translations[key][language]
    } catch(err){
        console.log("No translation found for " + key)
    }
}