import {useRef, useState} from "react";
import Html5QrcodePlugin from '../Html5QrcodePlugin';
import getTranslation from "../translations";
import { BarcodeItem } from "../type_declarations";


export default function BarcodeInput({barcode, setBarcode, footerRefCurrent}: {barcode: BarcodeItem, setBarcode:any, footerRefCurrent: any}){
  const [startScan, setStartScan] = useState(false);
  const [manualMode, setManualMode] = useState(false);
  const [manualEntry, setManualEntry] = useState(barcode.currentScan);

  const setCurrentScan = (scan: string)=> {
    setBarcode({...barcode, currentScan: scan})
    setStartScan(false);
    if(footerRefCurrent){
      setTimeout(()=>{footerRefCurrent.focus()})
    }
  }
  const onScanSuccess = (decodedText: any, decodedResult: any) => {
    setCurrentScan(decodedText)
  }
  const onScanFailure = (error: any) => {
    console.log(JSON.stringify(error))
  }

  return(
    <div className='mb-2 container'>
      {
        startScan && (
          <>
        <div className={`modal ${startScan ? "d-block": ""}`}>
          <div className={`modal-dialog modal-fullscreen ${startScan ? "show": ""}`}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-4" id="exampleModalFullscreenLabel">Scan barcode</h1>
                <button type="button" className="btn-close" onClick={(ev) => {setStartScan(false)}}>&nbsp;</button>
              </div>
              <div className="modal-body">
                <Html5QrcodePlugin 
                  fps={10}
                  qrbox={300}
                  disableFlip={false}
                  qrCodeErrorCallback={onScanFailure}
                  qrCodeSuccessCallback={onScanSuccess}/>
              </div>

              <div className='modal-footer'>
                <button type="button" className="btn btn-primary form-control" onClick={(ev) => {setStartScan(false)}}>Close</button>
                {/* <button type="button" className="btn btn-primary form-control" onClick={(ev) => {onScanSuccess("abc", "def")}}>Close</button> */}
              </div>

            </div>
          </div>
        </div>

          </>
        )
      }

      <label className="form-label">{barcode.name}</label>
      {
        manualMode ? (
            <div className="input-group">
                <input className="form-control" defaultValue={manualEntry} onChange={(ev) => setManualEntry(ev.target.value)}></input>
                <button className="btn" onClick={(ev) => {setCurrentScan(manualEntry);setManualMode(false)}}><i className="bi bi-check text-success fs-3"></i></button>
                <button className="btn" onClick={(ev) => setManualMode(false)}><i className="bi bi-x text-danger fs-3"></i></button>
            </div>) :
        <div className="d-flex">
            <div className="flex-grow-1 text-bg-light p-2" onClick={(ev) => setStartScan(prevState => !prevState)}>
                <div className="border text-center p-1" style={{borderStyle: "dashed !important"}}>
                    <div className="d-flex justify-content-center">
                        {barcode.currentScan && <div className="flex-grow-1">{barcode.currentScan}</div>}
                        <div>{getTranslation("click_to_scan")}</div>
                    </div>
                </div>
            </div>
            <button type="button" className="btn btn-secondary" onClick={(ev) => setManualMode(true)}>
                <i className="bi bi-keyboard"></i>
            </button>
            {/* <button type="button" className="ms-2 btn btn-success">
                <i className="bi bi-clipboard-check"></i>
            </button> */}
        </div>
      }

    </div>
  )
}