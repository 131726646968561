import pluralize from "pluralize"
import wordsToNumbers from "words-to-numbers";

export const language = process.env.REACT_APP_MONTHS_LANGUAGE || "ro"
const months: Record<string, string[]> = {
    "en": ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"],
    "ro": ["ianuarie", "februarie", "martie", "aprilie", "mai", "iunie", "iulie", "august", "septembrie", "octombrie", "noiembrie", "decembrie"]
}
export const splitWord = "next";
export const submitKeyword = {"ro": " trimite", "en": " submit"}[language] || " trimite"

const numberOfNumberExpression: Record<string, RegExp> = {
    "ro": /(\d+)( de) (\d)/g,
    "en": /(\d+)( of|)? (\d$|\d |ones|twos|threes|fours|fives|sixes|sevenths|eights|nines)/g,
}

  
const replaceNumberOfNumberExpression = (input: string) => {

    const matches = [...input.matchAll(numberOfNumberExpression[language])]
    for(let i=0; i<matches.length; i++){
        const [exp, a, _, b] = matches[i]
        const singularized_b = wordsToNumbers(pluralize.singular(b))+""
        if(parseInt(a) < 10){
            input = input.replace(exp, singularized_b.padStart(parseInt(a), singularized_b))
        }
    }

    return input
}

export const transforms = () => {
    let transformVar: Record<string, any> =  {
        "string": function(input: string){return replaceNumberOfNumberExpression(input)},
        "noSpaces": function(input: string){return replaceNumberOfNumberExpression(input).replace(/\s/g,"")},
        "capitalize": function(input: string){return replaceNumberOfNumberExpression(input).toUpperCase()},
        "date": function(input: string){
            let slices = input.split(" ")
            slices[0] = wordsToNumbers(slices[0]) + ""
            input = slices.join(" ")
            const dayOfMonthExp = /(\d)(st|nd|rd|th) of/
            const [matchExp, number] = input.match(dayOfMonthExp) || []
            if(number){
                input = input.replace(matchExp!, number)
            }

            slices = input.split(" ")
            if(slices.length === 3){
                const monthIndex = months[language].indexOf(slices[1].trim().toLowerCase())
                slices[2] = slices[2].replace(/ |-/, "")
                if(monthIndex>=0){
                    slices[1] = String(monthIndex+1).padStart(2, '0')
                    return slices.join(".")
                }
            }
            return input
        },
    }

    if (language === 'en'){ 
        transformVar['number'] = function(input: string){return wordsToNumbers(input)}
    }

    return transformVar
} 

export const getTransformFunc = (transform: string) => {
    if(transform in transforms()){
        return transforms()[transform]
    } else{
        return function(input: string){return input}
    }
}