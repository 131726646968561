import React, { useState, useEffect } from 'react';
import { getColumns, saveColumnInDb, clearEntries, clearColumns} from "./db"
import { transforms } from './columnTransform';
import { useAuth } from "./AuthContext"
import { FIXED_BARCODE_COLUMN, BARCODE_COLUMN } from './common';
import { toast } from 'react-toastify';
import { Item } from './type_declarations';
import getTranslation from './translations';

function Settings(){
  const {currentUser} = useAuth();
  let [columns, setColumns] = useState<Item[]>([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getColumns(currentUser, (columns: Item[]) =>{
      setColumns(columns)
      setLoading(false)
    });
  },[])


  return (<div className="container">
      <div className="my-3 d-flex justify-content-between">
        <h3>{getTranslation("settings")}</h3>
        <a href="#" onClick={(e) => {clearColumns(currentUser);setColumns([])}}>Clear</a>
      </div>

      {loading ? (
  <div className="spinner-border" style={{width: "5rem", "height": "5rem"}} role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
):(
  <>
      {  columns.length > 0 && 
          <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
            </tr>
          </thead>
          <tbody>
            {columns.map((c, ix) => (
                <tr key={c.identifier}>
                <th scope="row">{ix}</th>
                <td>{c.name}</td>
                <td>{c.type}</td>
                </tr>
            ))}
          </tbody>
          </table>
      }

      <div className="card">
        <div className="card-body">
          <h5 className="card-title mb-3">{getTranslation("new_column")}</h5>
          <NewColumn columns={columns} setData={setColumns} currentUser={currentUser}/>
        </div>
      </div>

  </>
)}

  </div>)
}


function NewColumn({columns, setData, currentUser}:{columns: Item[], setData: any, currentUser: any}){
  const getIdentifier = (input: string) => {return input.toLowerCase().replaceAll(" ", "_")}
  const [currentColumn, setCurrentColumn] = useState<Item>(new Item())

  const saveColumn = (e: any) => {
    e.preventDefault()
    if(currentColumn.name === ""){
        alert("Name is required")
        return
    }
    if(columns.some(c => c.name === currentColumn.name)){
      alert("A column with this name already exists. Please choose another one!")
      return
    }

    if(currentColumn.type === FIXED_BARCODE_COLUMN && columns.some(c=>c.type === FIXED_BARCODE_COLUMN)){
      toast.error("You can have a single fixed barcode")
      return
    }

    const newData = [...columns, {...currentColumn}]
    setData(newData)
    saveColumnInDb(currentColumn, currentUser)
    setCurrentColumn(new Item())
    clearEntries(currentUser)
  }

  const selectChange = (e: any) => {
    setCurrentColumn({...currentColumn, type: e.target.value})
  }

  const setColumnName = (e: any) => {
    const value: string = e.target.value;
    setCurrentColumn({...currentColumn, name: value, identifier: getIdentifier(value)})
  }

  return (

      <form id="inputForm" className="row g-3" onSubmit={saveColumn}>
        <div className="col-md-6">
            <label htmlFor="columnName" className="form-label">Name</label>
            <input type="input" className="form-control" id="columnName" value={currentColumn.name} onChange={setColumnName}/>
        </div>
        <div className="col-md-6">
            <label htmlFor="inputType" className="form-label">Type</label>
            <select className="form-select" value={currentColumn.type} onChange={selectChange}>
                {Object.keys(transforms()).map((k:string) =>(
                    <option className="text-capitalize" key={k} value={k}>{k[0].toLocaleUpperCase() + k.substring(1)}</option>
                ))}
                <option className="text-capitalize" value={FIXED_BARCODE_COLUMN}>Fixed Barcode Scanner</option>
                <option className="text-capitalize" value={BARCODE_COLUMN}>Barcode Scanner</option>
            </select>
        </div>
        <div className="col-12">
            <button type="submit" className="btn btn-primary w-100"><i className="bi bi-plus-lg"></i>{getTranslation("add")}</button>
        </div>
      </form>
  )
}

export default Settings;
