import { useRef, useEffect } from "react";


export default function FormInput(props: any){
  const inputRef = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const c = props.data;
  const prevValue = usePrevious(c['value']);
  const barcode = props.barcode;

  useEffect(() => {
    const top = c['top'];
    if(divRef.current && inputRef.current && prevValue !== inputRef.current.value && (prevValue || inputRef.current.value) && top !== -1){
      //divRef.current.scrollIntoView();
      divRef.current.style.backdropFilter = 'blur(5000px)';
      divRef.current.style.position = 'fixed';
      divRef.current.style.top = c['top'] ? `${c['top'] - 80}px` : '80px';
      divRef.current.style.width = "85%";
      c['highlighted'] = true;
    } else if(divRef.current){
      divRef.current.style.backdropFilter = '';
      divRef.current.style.position = '';
      divRef.current.style.top = '';
      divRef.current.style.width = '';
      c['highlighted'] = false;
    }
  }, [props.data])
  return (
      <div className="mb-2" key={c['identifier']} ref={divRef}>
        <label htmlFor={c['identifier']} className="form-label text-capitalize">{c['name']}</label>
        <input
            type="input"
            className={c['value'] == "" ? "form-control" : "form-control border border-success"}
            disabled={barcode ? !barcode.currentScan : false}
            id={c['identifier']}
            value={c['value']}
            onChange={(e) => props.manualChange(e.target.value, c['identifier'])}
            ref={inputRef}
        />
      </div>
  );
}

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  },[value]);
  return ref.current;
}