import ProfileDropdown from "./ProfileDropdown";
import {useAuth} from "./AuthContext"
import { NavLink } from "react-router-dom";
import getTranslation from "./translations";


function Header() {
  const { currentUser } = useAuth()
  return (
  <nav className="navbar navbar-expand navbar-dark bg-dark" aria-label="Second navbar example">
    <div className="container">
      <NavLink to={"/"} className="navbar-brand" >Rinkt Inputs</NavLink>

      <div className="collapse navbar-collapse" id="navbarsExample02">
        <ul className="navbar-nav me-auto">
          <li className="nav-item">
            <NavLink to={"/list"} className="nav-link" >{getTranslation("entry_list_title")}</NavLink>
          </li>
        </ul>
        {currentUser ? <ProfileDropdown /> : ""}
      </div>
    </div>
  </nav>
  );
}

export default Header;
