import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database"
import { getStorage } from "firebase/storage";
import { getFirestore } from 'firebase/firestore';

const app = initializeApp({
  apiKey: "AIzaSyDPh7SxDlxxv__f0kSh-4-itiFzyvR-OG8",
  authDomain: "staging-rinkt.firebaseapp.com",
  databaseURL: "https://staging-rinkt-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "staging-rinkt",
  storageBucket: "staging-rinkt.appspot.com",
  messagingSenderId: "1051868239062",
  appId: "1:1051868239062:web:6a7559d32cead7c0eba577"
});
export const auth = getAuth(app)
export const db = getDatabase(app)
export const storage = getStorage(app)
export const firestore = getFirestore(app);

export default app