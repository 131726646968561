
import { Html5QrcodeScanner } from "html5-qrcode";
import React, { useEffect } from 'react';

const qrcodeRegionId = "html5qr-code-full-region";

function Html5QrcodePlugin(props: any){
    let html5QrcodeScanner: Html5QrcodeScanner | null = null;

    useEffect(() => {
        function createConfig(props: any) {
            var config: any = {};
            if (props.fps) {
                config.fps = props.fps;
            }
            if (props.qrbox) {
                config.qrbox = props.qrbox;
            }
            if (props.aspectRatio) {
                config.aspectRatio = props.aspectRatio;
            }
            if (props.disableFlip !== undefined) {
                config.disableFlip = props.disableFlip;
            }
            return config;
        }

        var config = createConfig(props);
        var verbose = props.verbose === true;

        // Suceess callback is required.
        if (!(props.qrCodeSuccessCallback )) {
            throw "qrCodeSuccessCallback is required callback.";
        }
        html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
        html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);

        return () => {
            html5QrcodeScanner?.clear().catch((error: any) => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        }
    }, [])

    return (
      <div id={qrcodeRegionId} />
    )
};

export default Html5QrcodePlugin;