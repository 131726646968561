
import { firestore, db } from "./firebase"

import { doc, deleteDoc , onSnapshot, collection, query, addDoc, setDoc, getDocs, getDoc } from "firebase/firestore";
import { toast } from 'react-toastify';
import { Item } from "./type_declarations";


export class Entry{
    identifier: string;
    value: string;
    name: string;

    constructor(){
        this.identifier = ""
        this.value = ""
        this.name = ""
    }
}

export class EntryRow{
    data: Entry[];
    creationDate: string
    uid?: string

    constructor(){
        this.data = []
        this.creationDate = new Date().toISOString()
        this.uid = ""
    }

}

const entriesPath = (currentUser: any) => {
    return `users/${currentUser.uid}/entries`
}

const columnsPath = (currentUser: any) => {
    return `users/${currentUser.uid}/columns`
}

const sortFunc = (a: any, b: any, key: string) => {
    if(a[key] < b[key]){
        return -1;
    }
    if(a[key] > b[key]){
        return 1;
    }
    return 0
}

export const getColumns = (currentUser: any, setColumns: any) => {
    const colsCollection = collection(firestore, columnsPath(currentUser))
    getDocs(colsCollection).then(
        (snap) =>{
            const data = (snap.docs.map((d) => (d.data() as Item))||[]).sort((a, b) => sortFunc(a, b, "creationDate"))
            setColumns(data)
        })
        .catch((err) => {toast.error("Unable to fetch data");})
}

export const saveColumnInDb = (column: Item, currentUser: any) => {
    addDoc(collection(firestore, columnsPath(currentUser)), column)
        .then((data) => {toast.success("Saved column")})
        .catch((err) => {toast.error("Unable to create column");})
}

export const getEntries = <E>(currentUser: any, setEntries: any) => {
    getDocs(collection(firestore, entriesPath(currentUser))).then(
        (snap) =>{
            let data = snap.docs.map((d) => {
                const entry = d.data() as EntryRow
                entry.uid = d.id
                return entry
            })||[]
            data.sort((a,b) => sortFunc(a, b, "creationDate"))
            setEntries(data);
        })
        .catch((err) => {toast.error("Unable to fetch data");})
}

export const saveEntry = <E>(entryRow: EntryRow, currentUser: any) => {
    addDoc(collection(firestore, entriesPath(currentUser)), entryRow)
        .then((data) => {toast.success("Saved row")})
        .catch((err) => {toast.error("Unable to save entry");})
}

export const clearEntries = (currentUser: any) => {
    const entriesCollection = collection(firestore, entriesPath(currentUser))
    getDocs(entriesCollection)
        .then((snap) =>{snap.docs.forEach((el) => deleteDoc(el.ref))})
        .catch((err) => {toast.error("Unable to fetch data");})
}

export const clearColumns = (currentUser: any) => {
    const colsCollection = collection(firestore, columnsPath(currentUser))
    getDocs(colsCollection)
        .then((snap) =>{snap.docs.forEach((el) => deleteDoc(el.ref))})
        .catch((err) => {toast.error("Unable to fetch data");})
}

export const deleteEntry = (currentUser: any, entryId: string) => {
    const docRef = doc(firestore, entriesPath(currentUser), entryId)
    deleteDoc(docRef).then(() => toast.success("Successfully deleted entry from database"))
                    .catch((err) => toast.error("Unable to delete entry"))
}