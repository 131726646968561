import { transforms } from './columnTransform';

export class Item {
    name: string;
    type: string;
    identifier: string;
    creationDate: string

    constructor(){
        this.name = ""
        this.type = Object.keys(transforms())[0]
        this.identifier = "new"
        this.creationDate = new Date().toISOString()
    }
}


export class BarcodeItem extends Item {
  currentScan: string;

  constructor(){
    super()
    this.currentScan = ""
  }
}